<template>
  <div>
    <div class="title">{{ context.title }}</div>
    <div
      class="select"
      v-if="isCorrect === undefined"
    >
      <div class="question">Where is this article from?</div>
      <button
        class="btn-primary"
        :disabled="isCorrect !== undefined"
        v-on:click="chooseSource(true)"
      >Babylon Bee
      </button>
      <button
        class="btn-secondary"
        :disabled="isCorrect !== undefined"
        v-on:click="chooseSource(false)"
      >Not Babylon Bee
      </button>
    </div>
    <div
      class="results"
      v-if="isCorrect !== undefined"
    >
      <div
        class="correct"
        v-if="isCorrect === true"
      >
        Correct!
      </div>

      <div
        class="incorrect"
        v-if="isCorrect === false"
      >
        Wrong!
      </div>
      <div class="response">
        <div class="article-source">This article is from <b>{{ this.context.source }}</b>.</div>
        <button
          class="btn-primary"
          v-on:click="goToNextArticle"
        >
          Keep Playing
        </button>
        <div class="response-options">
          <button
            class="btn-secondary"
            v-on:click="viewArticle"
          >
            View Article
          </button>
          <button
            class="btn-secondary"
            v-on:click="submitFeedback"
          >
            Submit Feedback
          </button>
          <button
            class="btn-secondary"
            v-clipboard:copy="currentLocation"
          >Copy Link</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { init, track } from 'insights-js'

import { sources } from '../../data/sources';

import getRandomSource from '../lib/getRandomSource';
import tryRunCode from '../lib/tryRunCode';

const getInsightsId = process.env.VUE_APP_GETINSIGHTS_ID;

async function getContext(hash) {
  const context = sources.find(source => source.hash === hash);
  return context;
}

export default {
  beforeMount() {
    if(getInsightsId) {
      tryRunCode(() => init(getInsightsId));
    }
  },
  async beforeRouteEnter(to, from, next) {
    const hash = to.params.hash;
    const context = await getContext(hash);

    if(context) {
      document.title = `Is it Babylon Bee? | ${context.title}`;
      next(vm => {
        vm.context = context;
      });
    } else {
      next('/404');
    }
  },
  async beforeRouteUpdate(to, from, next) {
    const hash = to.params.hash;
    const context = await getContext(hash);

    if(context) {
      document.title = `Is it Babylon Bee? | ${context.title}`;
      this.context = context;
      this.isCorrect = undefined;
      next();
    } else {
      next('/404');
    }
  },
  computed: {
    currentLocation() {
      return window.location.href
    }
  },
  data() {
    return {
      context: {},
      isCorrect: undefined
    };
  },
  methods: {
    chooseSource(isBabylonBeeChosen) {
      const isBabylonBee = this.context.source === 'Babylon Bee';
      const isCorrect = isBabylonBee === isBabylonBeeChosen;
      this.isCorrect = isCorrect;
      this.sendResults(isCorrect);
    },
    goToNextArticle() {
      const nextHash = getRandomSource(this.context.hash);
      this.$router.push(`/article/${nextHash}`);
    },
    sendResults(isCorrect) {
      if(getInsightsId) {
        tryRunCode(() => {
          track({
            id: 'article-answered',
            parameters: { 'article-id': this.context.hash }
          });

          track({
            id: `article-${this.context.hash}`,
            parameters: { 'is-correct': isCorrect }
          });
        });
      }
    },
    submitFeedback() {
      const title = encodeURIComponent(this.context.title);
      const link = encodeURIComponent(this.currentLocation);
      window.open(
        `https://docs.google.com/forms/d/e/1FAIpQLSelclTXVtd3mABryFLYmwMYfbZpWYghK5FlRysTpYjZzaj-KQ/viewform?usp=pp_url`
          + `&entry.860607598=${title}`
          + `&entry.846748312=${link}`
      );
    },
    viewArticle() {
      window.open(this.context.link);
    }
  }
};
</script>

<style scoped>
  .article-source {
    font-size: 1.2em;
  }

  .correct {
    color: #00BC8C;
    font-size: 2.5em;
    font-weight: 900;
  }

  .question {
    font-size: 1.2em;
  }

  .response {
    display: flex;
    flex-direction: column;
  }

  .response-options button {
    font-size: 1.1em;
  }

  .results {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
  }

  .results .btn-primary {
    font-size: 1.8em;
  }

  .select {
    display: flex;
    align-items: stretch;
    flex-direction: column;
    text-align: center;
  }

  .title {
    font-size: 2em;
    font-weight: 900;
    margin: 5vh auto;
    text-align: center;
  }

  .incorrect {
    color: #E74C3C;
    font-size: 2.5em;
    font-weight: 900;
  }

  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    .response-options {
      display: flex;
      flex-direction: column;
    }
  }
</style>
